import React from 'react'

import { Router } from "@reach/router"

import PageCasePreview from 'components/pageDataPreview/PageCasePreview'

const CasePreview = () => (
  <Router basepath={`/en/case_preview`}>
    <PageCasePreview path={'/:slug'} lang={'en'} />
  </Router>
)

export default CasePreview
